import { DataServiceQuery, IDataServiceRequest } from '@msdyn365-commerce/retail-proxy';

import { OperationResult } from '../entities/OperationResult';

export function addNewTruckToCartQuery(): DataServiceQuery {
    return new DataServiceQuery();
}

export function addNewTruckToCartInput(cartId: string): IDataServiceRequest {
    const query = addNewTruckToCartQuery();

    return query.createDataServiceRequestForOperation(
        'TrAddNewTruckToCart',
        true,
        OperationResult,
        'false',
        { bypassCache: 'get', returnEntity: 'OperationResult' },
        { cartId: cartId }
    );
}

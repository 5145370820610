import { DataServiceQuery, IDataServiceRequest } from '@msdyn365-commerce/retail-proxy';

import { OperationResult } from '../entities/OperationResult';

export function switchActiveTruckOnCartQuery(): DataServiceQuery {
    return new DataServiceQuery();
}

export function switchActiveTruckOnCartInput(truckNumber: string, cartId: string): IDataServiceRequest {
    const query = switchActiveTruckOnCartQuery();

    return query.createDataServiceRequestForOperation(
        'TrSwitchActiveTruckOnCart',
        true,
        OperationResult,
        'false',
        { bypassCache: 'get', returnEntity: 'OperationResult' },
        { truckNumber: truckNumber, cartId: cartId }
    );
}

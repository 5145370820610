export class DeliveryDetails {
    public BlackoutDates: string[];
    public LeadTime: number; // in days

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};

        this.BlackoutDates = odataObject.BlackoutDates?.length ? odataObject.BlackoutDates : [];
        this.LeadTime = odataObject.LeadTime;
    }
}

export class UnitOfMeasureConversionOption {
    public ItemId: string;
    public ProductVariantId: number;
    public FromUnitOfMeasure: string;
    public ToUnitOfMeasure: string;
    public Quantity: number;

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};

        this.ItemId = odataObject.ItemId;
        this.ProductVariantId = odataObject.ProductVariantId !== null ? parseInt(odataObject.ProductVariantId, 10) : 0;
        this.FromUnitOfMeasure = odataObject.FromUnitOfMeasure;
        this.ToUnitOfMeasure = odataObject.ToUnitOfMeasure;
        this.Quantity = odataObject.Quantity !== null ? parseFloat(odataObject.Quantity) : 1;
    }
}

import { DataServiceQuery, IDataServiceRequest } from '@msdyn365-commerce/retail-proxy';

import { ItemGroup } from '../entities/ItemGroup';

export function validateProductAvailabilityAtCustomerAddressQuery(): DataServiceQuery {
    return new DataServiceQuery();
}

export function validateProductAvailabilityAtCustomerAddressInput(customerId: string, itemGroups: ItemGroup[]): IDataServiceRequest {
    const query = validateProductAvailabilityAtCustomerAddressQuery();

    return query.createDataServiceRequestForOperation(
        'TrValidateProductAvailabilityAtCustomerAddress',
        true,
        ItemGroup,
        'true',
        { bypassCache: 'get', returnEntity: 'ItemGroup' },
        { customerId, itemGroups }
    );
}

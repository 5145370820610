import { DataServiceQuery, IDataServiceRequest } from '@msdyn365-commerce/retail-proxy';

import { UnitOfMeasureConversionOption } from '../entities/UnitOfMeasureConversionOption';

export function dataServiceQuery(): DataServiceQuery {
    return new DataServiceQuery();
}

export function calculateQuantityForUnitsOfMeasurementInput(
    productId: number,
    fromUnitOfMeasure: string,
    quantity: number
): IDataServiceRequest {
    const query = dataServiceQuery();

    return query.createDataServiceRequestForOperation(
        'TRCalculateQuantityForUnitsOfMeasurement',
        true,
        UnitOfMeasureConversionOption,
        'true',
        { bypassCache: 'get', returnEntity: 'UnitOfMeasureConversionOption' },
        { productId, fromUnitOfMeasure, quantity }
    );
}

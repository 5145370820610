import { AsyncResult, callActionOrExecute, IContext } from '@msdyn365-commerce/retail-proxy';

import { ItemGroup } from '../entities/ItemGroup';
import { validateProductAvailabilityAtCustomerAddressInput } from '../queries/validate-product-availability-at-customer-address.query';

export function validateProductAvailabilityAtCustomerAddressAsync(
    context: IContext,
    customerId: string,
    itemGroups: ItemGroup[]
): AsyncResult<ItemGroup[]> {
    const request = validateProductAvailabilityAtCustomerAddressInput(customerId, itemGroups);
    return callActionOrExecute<ItemGroup[]>(request, context.callerContext);
}

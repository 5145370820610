export interface IOperationResult {
    IsSuccess: boolean;
    ErrorMessage?: string;
}

export class OperationResult implements IOperationResult {
    public IsSuccess: boolean;
    public ErrorMessage: string;

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};

        this.IsSuccess = odataObject.IsSuccess;
        this.ErrorMessage = odataObject.ErrorMessage;
    }
}

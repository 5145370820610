import { AsyncResult, IContext } from '@msdyn365-commerce/retail-proxy';

import { UnitOfMeasureConversionOption } from '../entities/UnitOfMeasureConversionOption';
import { calculateQuantityForUnitsOfMeasurementInput } from '../queries/calculate-quantity-for-units-of-measurement.query';

export function calculateQuantityForUnitsOfMeasurementAsync(
    context: IContext,
    productId: number,
    fromUnitOfMeasure: string,
    quantity: number
): AsyncResult<UnitOfMeasureConversionOption[]> {
    const request = calculateQuantityForUnitsOfMeasurementInput(productId, fromUnitOfMeasure, quantity);

    return new AsyncResult<UnitOfMeasureConversionOption[]>((resolve, reject) => {
        request
            .execute<UnitOfMeasureConversionOption[]>(context.callerContext)
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                reject(error);
            });
    });
}

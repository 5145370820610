import { Address } from '@msdyn365-commerce/retail-proxy';

export class ItemDetails {
    public ItemId: string;
    public Quantity: number;
    public IsAvailable?: boolean;

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};

        this.ItemId = odataObject.ItemId;

        this.Quantity = odataObject.Quantity === null ? 0 : parseFloat(odataObject.Quantity);

        this.IsAvailable = odataObject.IsAvailable ?? false;
    }
}

export class ItemGroup {
    public GroupId: string;
    public Address: Address;
    public ItemDetails: ItemDetails[];

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};

        this.GroupId = odataObject.GroupId;

        this.Address = odataObject.Address ?? {};

        this.ItemDetails = odataObject.ItemDetails ?? [];
    }
}

import { DataServiceQuery, IDataServiceRequest } from '@msdyn365-commerce/retail-proxy';

import { DeliveryDetails } from '../entities/DeliveryDetails';

export function getDeliveryDetailsQuery(): DataServiceQuery {
    return new DataServiceQuery();
}

export function getDeliveryDetailsInput(addressRecId: number, itemIds: string[]): IDataServiceRequest {
    const query = getDeliveryDetailsQuery();

    return query.createDataServiceRequestForOperation(
        'TrGetDeliveryDetails',
        true,
        DeliveryDetails,
        'false',
        {
            bypassCache: 'get',
            returnEntity: 'DeliveryDetails'
        },
        { addressRecId, itemIds }
    );
}
